import React, { createContext, useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js'; // Import the BigNumber library

// Create a Wallet context
const WalletContext = createContext();

// Custom hook to use the Wallet context
export const useWallet = () => {
  return useContext(WalletContext);
};

// Wallet provider component
export const WalletProvider = ({ children }) => {
  // State for connected wallet address
  const [connectedWallet, setConnectedWallet] = useState(() => {
    return localStorage.getItem('connectedWallet') || null;
  });

  // State for USDT and TRON balances, initialized as BigNumber instances
  const [usdtBalance, setUsdtBalance] = useState(new BigNumber(0));
  const [tlBalance, setTlBalance] = useState(new BigNumber(0));
  const [dollarBalance, setDollarBalance] = useState(new BigNumber(0));
  const [eurBalance, setEurBalance] = useState(new BigNumber(0));
  const [tronBalance, setTronBalance] = useState(new BigNumber(0));

  // Function to connect wallet and save to localStorage
  const connectWallet = (address) => {
    setConnectedWallet(address);
    localStorage.setItem('connectedWallet', address);
  };

  // Function to disconnect wallet and remove from localStorage
  const disconnectWallet = () => {
    setConnectedWallet(null);
    localStorage.removeItem('connectedWallet');
    // Reset balances when disconnecting
    setUsdtBalance(new BigNumber(0));
    setTlBalance(new BigNumber(0));
    setDollarBalance(new BigNumber(0));
    setEurBalance(new BigNumber(0));
    setTronBalance(new BigNumber(0));
  };

  // Function to get the connected wallet address
  const getConnectedWallet = () => {
    return connectedWallet;
  };

  // Function to check if a wallet is connected
  const isWalletConnected = () => {
    return connectedWallet !== null;
  };

  // Function to update balances
  const updateBalances = (usdt, tron, tl, dollar, eur) => {
    try {
      setUsdtBalance(new BigNumber(Number(usdt)));
      setTlBalance(new BigNumber(Number(tl)));
      setDollarBalance(new BigNumber(Number(dollar)));
      setEurBalance(new BigNumber(Number(eur)));
      setTronBalance(new BigNumber(Number(tron)));
    } catch (error) {
      console.error('Error updating balances:', error);
    }
  };

  // Effect to clean up localStorage when wallet is disconnected
  useEffect(() => {
    if (!connectedWallet) {
      localStorage.removeItem('connectedWallet');
    }
  }, [connectedWallet]);

  return (
    <WalletContext.Provider 
      value={{ 
        usdtBalance,
        tlBalance,
        dollarBalance,
        eurBalance, 
        tronBalance, 
        connectWallet, 
        disconnectWallet, 
        updateBalances, 
        connectedWallet,
        getConnectedWallet,
        isWalletConnected
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
